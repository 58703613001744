import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import { AvForm, AvInput, AvGroup, AvRadioGroup, AvRadio,  AvFeedback } from "availity-reactstrap-validation"
import { Container, Row, Col, Button, Label, FormText, FormGroup, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap"
import styles from "../pages/css/index.module.css"

class AuthorizedSeller extends React.Component { 
	constructor(props) {
		super(props);

		this.handleValidSubmit = this.handleValidSubmit.bind(this);
		this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
		this.state = {
			modal: false,
		};
		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		this.setState({
			modal: !this.state.modal
		})
	}

	handleValidSubmit(event, values) {
		this.setState({values});
		let data = new FormData();

		// TODO: iterate through fields to append to FormData()
		data.append('applicantName', values.applicantName);
		data.append('tradeName', values.tradeName);
		data.append('primaryContact', values.primaryContact);
		data.append('jobTitle', values.jobTitle);
		
		// Business Focus
		if (values.rv === true) {
			data.append('rv', 'RV');
		} else {
			data.append('rv', '');
		}
		if (values.cargo === true) {
			data.append('cargo', 'Cargo/Utility Trailer');
		} else {
			data.append('cargo', '');
		}
		if (values.oem === true) {
			data.append('oem', 'OEM');
		} else {
			data.append('oem', '');
		}
		if (values.automotive === true) {
			data.append('automotive', 'Automotive');
		} else {
			data.append('automotive', '');
		}
		if (values.otherTrailer === true) {
			data.append('otherTrailer', 'Other Trailer');
		} else {
			data.append('otherTrailer', '');
		}
		if (values.otherFocus === true) {
			data.append('otherFocus', 'Other');
		} else {
			data.append('otherFocus', '');
		}

		data.append('otherFocusList', values.otherFocusList);
		data.append('physicalAddress', values.physicalAddress);
		data.append('mailingAddress', values.mailingAddress);        
		data.append('phone',values.phone);
		data.append('email',values.email);
		data.append('website',values.website);
		data.append('facebookUrl',values.facebookUrl);
		data.append('entityType', values.entityType);
		data.append('yearIncorporated',values.yearIncorporated);
		data.append('yearsInBusiness',values.yearsInBusiness);
		data.append('stateIncorporated',values.stateIncorporated);
		data.append('duns',values.duns);
		data.append('ein',values.ein);
		data.append('fedTaxId',values.fedTaxId);
		data.append('resaleCertificateNumber',values.resaleCertificateNumber);
		data.append('resaleCertificateIssuingState',values.resaleCertificateIssuingState);
		data.append('account',values.account);
		data.append('accountNumber',values.accountNumber);
		data.append('salesRep',values.salesRep);
		data.append('declaredBankruptcy',values.declaredBankruptcy);
		data.append('principalBankruptcy',values.principalBankruptcy);
		data.append('lawsuits',values.lawsuits);
		data.append('additionalBankruptcy',values.additionalBankruptcy);
		data.append('purchaseFrom',values.purchaseFrom);
		data.append('distWholeSale',values.distWholeSale);
		data.append('storeProducts',values.storeProducts);
		data.append('storeAdditionalInventory',values.storeAdditionalInventory);
		data.append('fulfillmentServices',values.fulfillmentServices);
		data.append('brickMortar',values.brickMortar);
		data.append('brickMortarLocations',values.brickMortarLocations);
		data.append('additionalProducts',values.additionalProducts);
		data.append('relationshipLengthMonths',values.relationshipLengthMonths);
		data.append('relationshipLengthYears',values.relationshipLengthYears);
		data.append('progressMfgList',values.progressMfgList);
		data.append('itemizedProducts',values.itemizedProducts);

		// Products
		if (values.equalizer === true) {
			data.append('equalizer', 'Equal-i-zer hitch');
		} else {
			data.append('equalizer', '');
		}
		if (values.equalizerParts === true) {
			data.append('equalizerParts', 'Equal-i-zer parts & accessories');
		} else {
			data.append('equalizerParts', '');
		}
		if (values.fastwayFlash === true) {
			data.append('fastwayFlash', 'Fastway Flash');
		} else {
			data.append('fastwayFlash', '');
		}
		if (values.fastwayPartsAccessories === true) {
			data.append('fastwayPartsAccessories', 'Fastway Parts & Accessories');
		} else {
			data.append('fastwayPartsAccessories', '');
		}
		if (values.fastwayE2 === true) {
			data.append('fastwayE2', 'Fastway e2 hitch');
		} else {
			data.append('fastwayE2', '');
		}
		if (values.otherProducts === true) {
			data.append('otherProducts', 'Other');
		} else {
			data.append('otherProducts', '');
		}

		data.append('otherProducts',values.otherProductsList);
		data.append('thirdPartyRequest',values.thirdPartyRequest);    
		data.append('form', values.formType);

		fetch('https://apis.progressmfg.com/', {
			cors: 'no-cors',
			method: 'POST', 
			body: data
		}).then((res) => {
			this.toggle();
		}).then(() => {
			this.form && this.form.reset();
		}).catch(err => console.log(err));
	}

	handleInvalidSubmit(event, errors, values) {
		this.setState({event, errors, values});
	}

	render() {
		const report = this.props.data.craft.content[0];
		return (
			<div>
				<Header/>
					<div className={styles.heroInterior}>
						<Container>
							<Row>
								<Col md={{ offset: 2, order: 2, size: 8 }} xs="12">
									<div className={styles.faqContent}>
										<h1>{report.mainContentHeadline}</h1>
									</div>
								</Col>
							</Row>
								<Row>
									<Col md={{ offset: 2, order: 2, size: 8 }} xs="12">
										<div className={styles.faqCard}>
											<AvForm className={styles.contactForm} onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit} name="reportViolation" id="reportViolation" ref={c => (this.form = c)} encType="multipart/form-data">
												<div dangerouslySetInnerHTML={{ __html: report.mainContent }}></div>
														<FormGroup className={styles.wholeInputAgreement}>
																<Label>I have read and I understand that if, after filling out the application, I am approved to sell products of Progress Mfg. Inc. on third-party websites, that my selling of products of Progress Mfg. Inc. on third party websites will be subject to the terms of the <a href="/assets/pdfs/Third-Party-Marketplace-Authorized-Online-Seller-Agreement.pdf" target="_blank">Third Party Marketplace Authorized Online Seller Agreement</a></Label>
																<div>
																		<AvGroup check>
																				<AvInput type="checkbox" name="agreement" required/>
																				<Label check for="agreement"> I Agree</Label>
																		</AvGroup>
																</div>
														</FormGroup>                           
														<AvGroup className={styles.wholeInput}>
																<Label for="applicantName">Applicant/Company Legal Name <span className={styles.requiredMark}>*</span>:</Label>
																<AvInput type="text" name="applicantName" id="applicantName" required />
																<AvFeedback>Please enter a name.</AvFeedback>
														</AvGroup>                            
														<AvGroup className={styles.wholeInput}>
																<Label for="tradeName">DBA/Trade Name(s):</Label>
																<AvInput type="text" name="tradeName" id="tradeName" />
																<AvFeedback>Please enter a name.</AvFeedback>
														</AvGroup>                            
														<AvGroup className={styles.halfInput}>
																<Label for="primaryContact">Primary Contact:</Label>
																<AvInput type="text" name="primaryContact" id="primaryContact"/>
														</AvGroup> 
														<AvGroup className={styles.halfInput}>
																<Label for="jobTitle">Title:</Label>
																<AvInput type="text" name="jobTitle" id="jobTitle"/>
														</AvGroup>  
														<AvGroup className={styles.halfInput}>
																<Label for="physicalAddress">Applicant Physical Address <span className={styles.requiredMark}>*</span>:<br/><br/></Label>
																<AvInput type="textarea" name="physicalAddress" id="physicalAddress"  required/>
														</AvGroup>
														<AvGroup className={styles.halfInput}>
																<Label for="mailingAddress">Applicant Mailing Address (<i>if different from physical address</i>):</Label>
																<AvInput type="textarea" name="mailingAddress" id="mailingAddress" />
														</AvGroup>
														<AvGroup className={styles.halfInput}>
																<Label for="phone">Phone Number:</Label>
																<AvInput type="telephone" name="phone" id="phone" />
														</AvGroup>    
														<AvGroup className={styles.halfInput}>
																<Label for="email">Email <span className={styles.requiredMark}>*</span>:</Label>
																<AvInput type="email" name="email" id="email" required/>
																<AvFeedback>Please enter a valid email address.</AvFeedback>
														</AvGroup>                          
														<AvGroup className={styles.halfInput}>
																<Label for="website">Website:</Label>
																<AvInput type="text" name="website" id="website" />
														</AvGroup>    
														<AvGroup className={styles.halfInput}>
																<Label for="facebookUrl">Facebook URL:</Label>
																<AvInput type="text" name="facebookUrl" id="facebookUrl" />
														</AvGroup>    
														<AvRadioGroup className={styles.wholeInput} name="entityType">
																<Label>Entity Type:</Label>
																<div className={styles.halfCheckInput}>
																		<AvRadio label="Corporation" value="Corporation" />
																		<AvRadio label="LLC" value="LLC" />
																</div>
																<div className={styles.halfCheckInput}>
																		<AvRadio label="Partnership" value="Partnership" />
																		<AvRadio label="Sole Proprietorship" value="Sole Proprietorship" />
																</div>
														</AvRadioGroup>
														<AvGroup className={styles.halfInput}>
																<Label for="yearIncorporated">Year Incorporated:</Label>
																<AvInput type="text" name="yearIncorporated" id="yearIncorporated" />
														</AvGroup> 
														<AvGroup className={styles.halfInput}>
																<Label for="yearsInBusiness">Years In Business:</Label>
																<AvInput type="text" name="yearsInBusiness" id="yearsInBusiness" />
														</AvGroup> 
														<AvGroup className={styles.halfInput}>
																<Label for="stateIncorporated">State Incorporated:</Label>
																<AvInput type="text" name="stateIncorporated" id="stateIncorporated" />
														</AvGroup> 
														<AvGroup className={styles.halfInput}>
																<Label for="duns">DUNS:</Label>
																<AvInput type="text" name="duns" id="duns" />
														</AvGroup> 
														<AvGroup className={styles.halfInput}>
																<Label for="ein">EIN:</Label>
																<AvInput type="text" name="ein" id="ein" />
														</AvGroup> 
														<AvGroup className={styles.halfInput}>
																<Label for="fedTaxId">Fed Tax ID:</Label>
																<AvInput type="text" name="fedTaxId" id="fedTaxId" />
														</AvGroup> 
														<AvGroup className={styles.halfInput}>
																<Label for="resaleCertificateNumber">Resale Certificate Number:</Label>
																<AvInput type="text" name="resaleCertificateNumber" id="resaleCertificateNumber" />
														</AvGroup> 
														<AvGroup className={styles.halfInput}>
																<Label for="resaleCertificateIssuingState">Resale Certificate Issuing State:</Label>
																<AvInput type="text" name="resaleCertificateIssuingState" id="resaleCertificateIssuingState" />
														</AvGroup> 
														<FormGroup className={styles.wholeInput}>
																<Label>What is the focus of your business?</Label>
																<div className={styles.halfCheckInput}>
																		<AvGroup check>
																				<AvInput type="checkbox" name="rv"/>
																				<Label check for="rv"> RV</Label>
																		</AvGroup>
																		<AvGroup check>
																				<AvInput type="checkbox" name="cargo" />
																				<Label check for="cargo"> Cargo/Utility Trailer</Label>
																		</AvGroup>
																		<AvGroup check>
																				<AvInput type="checkbox" name="oem"/>
																				<Label check for="oem"> OEM</Label>
																		</AvGroup>
																</div>
																<div className={styles.halfCheckInput}>
																		<AvGroup check>
																				<AvInput type="checkbox" name="automotive"/>
																				<Label check for="automotive"> Automotive</Label>
																		</AvGroup>
																		<AvGroup check>
																				<AvInput type="checkbox" name="otherTrailer"/>
																				<Label check for="otherTrailer"> Other Trailer</Label>
																		</AvGroup>
																		<AvGroup check>
																				<AvInput type="checkbox" name="otherFocus"/>
																				<Label check for="otherFocus"> Other</Label>
																		</AvGroup>
																</div>
														</FormGroup>
														<AvGroup className={styles.wholeInput}>
																<Label for="otherFocusList">If Other:</Label>
																<AvInput type="text" name="otherFocusList" id="otherFocusList"/>
														</AvGroup>  
														<AvRadioGroup className={styles.wholeInput} name="account">
																<Label>Do you currently have or previously had an account with Progress Mfg. Inc.?</Label>
																<div className={styles.halfCheckInput}>
																		<AvRadio label="Yes" value="Yes" />
																		<AvRadio label="No" value="No" />
																</div>
														</AvRadioGroup>
														<AvGroup className={styles.halfInput}>
																<Label for="accountNumber">If yes, please provide your Account Number:</Label>
																<AvInput type="text" name="accountNumber" id="accountNumber" />
														</AvGroup> 
														<AvGroup className={styles.halfInput}>
																<Label for="salesRep">If yes, please provide your Sales Rep(s):</Label>
																<AvInput type="text" name="salesRep" id="salesRep" />
														</AvGroup> 
														<AvRadioGroup className={styles.wholeInput} name="declaredBankruptcy">
																<Label>Have you ever declared bankruptcy?</Label>
																<div className={styles.halfCheckInput}>
																		<AvRadio label="Yes" value="Yes" />
																		<AvRadio label="No" value="No" />
																</div>
														</AvRadioGroup>
														<AvRadioGroup className={styles.wholeInput} name="principalBankruptcy">
																<Label>Has any company, in which any of your principals have an ownership interest, ever declared bankruptcy?</Label>
																<div className={styles.halfCheckInput}>
																		<AvRadio label="Yes" value="Yes" />
																		<AvRadio label="No" value="No" />
																</div>
														</AvRadioGroup>
														<AvRadioGroup className={styles.wholeInput} name="lawsuits">
																<Label>Do you have any pending lawsuits?</Label>
																<div className={styles.halfCheckInput}>
																		<AvRadio label="Yes" value="Yes" />
																		<AvRadio label="No" value="No" />
																</div>
														</AvRadioGroup>
														<AvGroup className={styles.wholeInput}>
																<Label for="additionalBankruptcy">If you answered yes to the previous three questions, please provide an additional explanation on the matter:</Label>
																<AvInput type="textarea" name="additionalBankruptcy" id="additionalBankruptcy" />
														</AvGroup>
														<AvRadioGroup className={styles.wholeInput} name="purchaseFrom">
																<Label>Do you purchase products from any distributors or wholesalers?</Label>
																<div className={styles.halfCheckInput}>
																		<AvRadio label="Yes" value="Yes" />
																		<AvRadio label="No" value="No" />
																</div>
														</AvRadioGroup>
														<AvGroup className={styles.wholeInput}>
																<Label for="distWholeSale">If yes, please list all distributors/wholesalers:</Label>
																<AvInput type="textarea" name="distWholeSale" id="distWholeSale" />
														</AvGroup>
														<AvRadioGroup className={styles.wholeInput} name="storeProducts">
																<Label>Do you store or intend to store inventory of products at a location other than your physical address itendified above?</Label>
																<div className={styles.halfCheckInput}>
																		<AvRadio label="Yes" value="Yes" />
																		<AvRadio label="No" value="No" />
																</div>
														</AvRadioGroup>
														<AvGroup className={styles.wholeInput}>
																<Label for="storeAdditionalInventory">If yes, please provide the additional address/addresses where you will be storing or currently store inventory:</Label>
																<AvInput type="textarea" name="storeAdditionalInventory" id="storeAdditionalInventory" />
														</AvGroup>
														<AvRadioGroup className={styles.wholeInput} name="thirdParty">
																<Label>Do you use any third-party fulfillment service to store inventory or fulfill orders?</Label>
																<div className={styles.halfCheckInput}>
																		<AvRadio label="Yes" value="Yes" />
																		<AvRadio label="No" value="No" />
																</div>
														</AvRadioGroup>
														<AvGroup className={styles.wholeInput}>
																<Label for="fulfillmentServices">If yes, please list all fulfillment services and the services provided:</Label>
																<AvInput type="textarea" name="fulfillmentServices" id="fulfillmentServices" />
														</AvGroup>
														<AvRadioGroup className={styles.wholeInput} name="brickMortar">
																<Label>Do you have a brick and mortar storefront?</Label>
																<div className={styles.halfCheckInput}>
																		<AvRadio label="Yes" value="Yes" />
																		<AvRadio label="No" value="No" />
																</div>
														</AvRadioGroup>
														<AvGroup className={styles.wholeInput}>
																<Label for="brickMortarLocations">If yes, please provide the number of locations and location address/addresses:</Label>
																<AvInput type="textarea" name="brickMortarLocations" id="brickMortarLocations" />
														</AvGroup>
														<AvGroup className={styles.wholeInput}>
																<Label for="additionalProducts">What other towing product brands do you sell?</Label>
																<AvInput type="textarea" name="additionalProducts" id="additionalProducts" />
														</AvGroup>
														<FormGroup className={styles.wholeInput}>
																<Label>What Progress Mfg. Inc. products are you requesting to sell?</Label>
																<div className={styles.halfCheckInput}>
																		<AvGroup check>
																				<AvInput type="checkbox" name="equalizer" />
																				<Label check for="equalizer"> Equal-i-zer<sup>&reg;</sup> hitch</Label>
																		</AvGroup>
																		<AvGroup check>
																				<AvInput type="checkbox" name="equalizerParts" />
																				<Label check for="equalizerParts"> Equal-i-zer<sup>&reg;</sup> parts &amp; accessories</Label>
																		</AvGroup>
																		<AvGroup check>
																				<AvInput type="checkbox" name="fastwayFlash" />
																				<Label check for="fastwayFlash"> Fastway Flash<sup>&trade;</sup></Label>
																		</AvGroup>
																</div>
																<div className={styles.halfCheckInput}>
																		<AvGroup check>
																				<AvInput type="checkbox" name="fastwayPartsAccessories" />
																				<Label check for="fastwayPartsAccessories"> Fastway Parts &amp; Accessories</Label>
																		</AvGroup>
																		<AvGroup check>
																				<AvInput type="checkbox" name="fastwayE2" />
																				<Label check for="fastwayE2"> Fastway e2<sup>&reg;</sup> hitch</Label>
																		</AvGroup>
																		<AvGroup check>
																				<AvInput type="checkbox" name="otherProducts" />
																				<Label check for="otherProducts"> Other</Label>
																		</AvGroup>
																</div>
														</FormGroup>
														<AvGroup className={styles.wholeInput}>
																<Label for="otherProductsList">If other, please list:</Label>
																<AvInput type="textarea" name="otherProductsList" id="otherProductsList" />
														</AvGroup>
														<AvGroup className={styles.wholeInput}>
																<Label for="thirdPartyRequest">What third party marketplaces are you requesting to sell on?</Label>
																<FormText color="muted">
																		Please identify all websites where you wish to market for sale and sell products, separated by commas, <em>e.g: <b>amazon.com, ebay.com, etc.</b></em>
																</FormText>
																<AvInput type="textarea" name="thirdPartyRequest" id="thirdPartyRequest" />
														</AvGroup>           
														
														<AvGroup className={styles.wholeInput}>
																<Label><strong>The length (in terms of months and/or years) of Applicant’s seller relationship with Progress Mfg. Inc.</strong></Label>
														</AvGroup>           
														<AvGroup className={styles.halfInput}>
																<Label for="relationshipLengthYears">Years</Label>
																<AvInput type="text" name="relationshipLengthYears" id="relationshipLengthYears" />
														</AvGroup>           
														<AvGroup className={styles.halfInput}>
																<Label for="relationshipLengthMonths">Months</Label>
																<AvInput type="text" name="relationshipLengthMonths" id="relationshipLengthMonths" />
														</AvGroup>           
														<AvGroup className={styles.wholeInput}>
																<Label for="progressMfgList">A list of specific Progress Mfg. Inc. products sold by Applicant</Label>
																<AvInput type="textarea" name="progressMfgList" id="progressMfgList" />
														</AvGroup>     
														<AvGroup className={styles.wholeInput}>
																<Label for="itemizedProducts">Approximate itemized number of Progress Mfg. In products sold by Applicant and a monthly (or quarterly) report of revenue derived from those sales, for the time period identified in section <br/>(a).  If the Applicant has been selling Progress Mfg. Products for longer than three years preceding the date of this application, then provide an approximate itemized number of products sold and a monthly (or quarterly) report of the revenue derived from those sales for the prior three years preceding the date of this application.</Label>
																<AvInput type="textarea" name="itemizedProducts" id="itemizedProducts" />
														</AvGroup>     


														<AvGroup className={styles.wholeInput}>
																<AvInput type="hidden" name="formType" id="formType" required value="seller-application"/>
														</AvGroup>                                     
														<Button className={styles.formSubmitButton}>Request Authorization</Button>
												</AvForm>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				<Footer/>
				<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
					<ModalHeader className={styles.modalHeadline} toggle={this.toggle}>{report.thankYouHeadline}</ModalHeader>
					<ModalBody dangerouslySetInnerHTML={{ __html: report.thankYouContent }}></ModalBody>
					<ModalFooter>
						<Button className={styles.formSubmitButton} onClick={this.toggle}>Close</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}
}


export const query = graphql`
  query AuthorizedPage {
    craft {
      content: entries(siteId: "4", slug: "authorized-seller-application", status: "disabled") {
        ... on craft_authorizedSellerApplication_authorizedSellerApplication_Entry {
          id
          mainContentHeadline
          mainContent 
          thankYouHeadline
          thankYouContent
        }
      }
    }
  }
`;

export default AuthorizedSeller